import React from "react";
import FaIcon from '../utils/icon.js';
import footerStyles from '../styles/components/footer.module.scss';

let year =  new Date();
year = year.getFullYear();

const Footer = () => (
    <footer className={footerStyles.footer}>
        <p className={footerStyles.copyright}>Created with <FaIcon type="fas" icon="heart" /> and <FaIcon type="fas" icon="laptop-code" /> by M&aacute;rio Duarte &copy; {year}, powered by Gatsby</p>

        <ul className={footerStyles.socialNavigation}>
            <li><a href="https://www.linkedin.com/in/mario-duarte-developer/" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="linkedin icon" type="fab" icon="linkedin-in" /></a></li>
            <li><a href="https://twitter.com/MDesignsuk" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="twitter icon" type="fab" icon="twitter" /></a></li>
            <li><a href="https://www.instagram.com/m.duarte_/" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="instagram icon" type="fab" icon="instagram" /></a></li>
            <li><a href="https://www.flickr.com/photos/mariodesigns" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="flickr icon" type="fab" icon="flickr" /></a></li>
            <li><a href="https://codepen.io/MarioDesigns" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="codepen icon" type="fab" icon="codepen" /></a></li>
            <li><a href="https://github.com/Mario-Duarte/" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="github icon" type="fab" icon="github" /></a></li>
            <li><a href="https://www.behance.net/mdesignsuk" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="behance icon" type="fab" icon="behance" /></a></li>
            <li><a href="https://dribbble.com/MDesignsuk" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="dribbble icon" type="fab" icon="dribbble" /></a></li>
        </ul>
    </footer>
);

export default Footer 