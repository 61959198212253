import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FaIcon from '../utils/icon.js';
import logo from "../../static/logo_1.svg";
import headerStyles from "../styles/components/header.module.scss";
import $ from "jquery/dist/jquery";
//import Swiper from "swiper";

const SiteTitle = () => {

    const data = useStaticQuery(graphql`
            query dataQuery {
                site(siteMetadata: {author: {}, title: {}}) {
                    siteMetadata {
                        title
                    }
                }
            }
        `);

    return (
        <div className={headerStyles.headerLogo}><a href="/"><img src={logo} alt={data.site.siteMetadata.title} /></a></div>
    );

}

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    // If we import styles into the component in a way that generates unique classes
    // we need to make sure that any event using it also reflects the same unique class generated

    componentDidMount() {
        let $header = $(`.${headerStyles.header}`);
        let $nav = $header.find('nav');
        let $hamburger = $(`.${headerStyles.hamburger}`);
        let $navigationItem = $(`.${headerStyles.navigation} li`);

        $hamburger.on('click', function () {
            $(this).toggleClass(headerStyles.openHamburger);
            $nav.toggleClass(headerStyles.openNav);
        });

        $navigationItem.on('click', () => {
            if ( $nav.hasClass(headerStyles.openNav) ) {
                $hamburger.removeClass(headerStyles.openHamburger);
                $nav.removeClass(headerStyles.openNav);
            }
        });

    }

    render() {

        return (
            <header className={headerStyles.header}>
                <SiteTitle />

                <nav className={headerStyles.navigation}>
                    {/* <li><Link to="/blog">Blog</Link></li> */}
                    <li className={headerStyles.mobonly}><a href="https://www.linkedin.com/in/mario-duarte-developer/" target="_blank" rel="noopener noreferrer">LinkedIn <FaIcon type="fab" icon="linkedin-in" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://twitter.com/MDesignsuk" target="_blank" rel="noopener noreferrer">Twitter <FaIcon type="fab" icon="twitter" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://www.instagram.com/m.duarte_/" target="_blank" rel="noopener noreferrer">Instagram <FaIcon type="fab" icon="instagram" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://www.flickr.com/photos/mariodesigns" target="_blank" rel="noopener noreferrer">Flickr <FaIcon type="fab" icon="flickr" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://codepen.io/MarioDesigns" target="_blank" rel="noopener noreferrer">CodePen <FaIcon type="fab" icon="codepen" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://github.com/Mario-Duarte/" target="_blank" rel="noopener noreferrer">GitHub <FaIcon type="fab" icon="github" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://www.behance.net/mdesignsuk" target="_blank" rel="noopener noreferrer">Behance <FaIcon type="fab" icon="behance" /></a></li>
                    <li className={headerStyles.mobonly}><a href="https://dribbble.com/MDesignsuk" target="_blank" rel="noopener noreferrer">Dribbble <FaIcon type="fab" icon="dribbble" /></a></li>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </nav>

                <figure className={headerStyles.hamburger}>
                    <div className="inner">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </figure>

            </header>
        );
    }

}

export default Header;